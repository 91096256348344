import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectCategory } from '../slices/categorySlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Category({ parentValue, handleClick }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [features, setCategories] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CLIENT_API_URL}/landing/quary/get_all`)
      .then((response) => {
        const { hierarchicaldata } = response.data.payload;
        setCategories(hierarchicaldata);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleImageListItemClick = (id) => {
    console.log('click c', id);
    dispatch(selectCategory({ id, level: 1 }));
    handleClick(id, 0);
    navigate(`/moreDetails/${id}`);
  };

  return (
    <div className="p-5 flex justify-center items-center shadow-md border-b bg-gray-50">
      <ul className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full max-w-6xl mx-auto">
        {features && features.length > 0 && (
          <>
            {features
              .filter((item) => item.parent == parentValue)
              .map((item, id) => (
                <li
                  key={id}
                  className="bg-white space-y-3 p-4 rounded-lg flex gap-4 items-center shadow hover:shadow-md transition-shadow duration-300 cursor-pointer"
                  onClick={() => handleImageListItemClick(item.id)}
                >
                  <div className="bg-green-400 p-2 flex justify-center items-center rounded-full">
                    <img
                      width="25"
                      height="25"
                      src={item.img_url}
                      alt="Icon"
                      className="rounded-full"
                    />
                  </div>
                  <div>
                    <h4 className="text-lg text-gray-800 font-semibold">{item.name}</h4>
                    <p className="text-sm text-gray-600">{item.desc}</p>
                  </div>
                </li>
              ))}
          </>
        )}
      </ul>
    </div>
  );
}

export default Category;
