// <MyCard image="assets/images/111.jpeg" buttons={['Analytics', 'tools']} />
import React from 'react';
import MyCard from './MyCard';

const AlignItemsList = ({ locations, toggleModal, handleMouseEnter, handleMouseLeave }) => {
  return (
    <div className="flex flex-wrap justify-center items-start overflow-y-auto rounded-lg m-5 p-4 bg-gray-200 max-h-[70vh]">
      {locations.map((location, index) => (
        <div key={index} className="w-full md:w-1/2 p-2">
          <MyCard
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            toggleModal={toggleModal}
            location={location}
            buttons={['Analytics', 'tools']}
          />
        </div>
      ))}
    </div>
  );
};

export default AlignItemsList;
