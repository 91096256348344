import React from 'react';
import { FormControl, MenuItem, Select, styled } from '@mui/material';

const CustomSelect = styled(Select)({
  border: '1px solid #3CC68A',
  maxWidth: '150px'
});

const CustomMenuItem = styled(MenuItem)({
  color: 'darkgreen'
});

const Dropdown = ({ options = [], value, onChange, name }) => {
  // Default empty array for options
  return (
    <FormControl fullWidth variant="outlined">
      <CustomSelect
        labelId="dropdown-label"
        label="Dropdown"
        defaultValue={'all city'}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {options.map((option, index) => (
          <CustomMenuItem key={index} value={option}>
            {option}
          </CustomMenuItem>
        ))}
      </CustomSelect>
    </FormControl>
  );
};

export default Dropdown;
