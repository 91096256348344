import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LandingPage from './LandingPage';
import Two from './Two';
import MapComponent from '../components/MapComponent';
import { useAppSelector } from 'app/redux/store';
import Loading from '../components/Loading/Loading';
import { set } from 'lodash';

const steps = ['Main category', 'Filters', 'Select on Map'];

const getData = () => {};

const Main = () => {
  const [activeScreen, setActiveScreen] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [haveFilters, setHaveFilters] = useState(false);
  const [all, setAll] = useState(null);
  const [loading, setLoading] = useState(true);
  const [parent, setParent] = useState(0);
  const [inputValues, setInputValues] = useState({});

  const { currentLevelx, parent: parenyx } = useAppSelector((state) => state.category);

  const getLandingData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_CLIENT_API_URL}/landing/quary/get_all`)
      .then((response) => {
        const { hierarchicaldata, filters } = response.data.payload;
        setAll({ categories: hierarchicaldata, filters });
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    getLandingData();
  }, []);

  const handleInputChange = (code, value) => {
    setInputValues((prevInputValues) => ({
      ...prevInputValues,
      [code]: value
    }));
  };

  const handleCategorySelect = (id, level) => {
    const updatedSelectedCategories = [...selectedCategories.slice(0, level), id];
    setSelectedCategories(updatedSelectedCategories);
    setParent(id);
    if (level === 0) {
      setActiveScreen(1);
    }
    setCurrentLevel(level + 1);
  };

  const back = (screen) => {
    setActiveScreen(screen);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {activeScreen === 0 && (
            <LandingPage features={all.categories} handleClick={handleCategorySelect} />
          )}
          {activeScreen === 1 && (
            <MapComponent
              back={back}
              steps={steps}
              activeScreen={activeScreen}
              filters={all.filters}
              handleInputChange={handleInputChange}
              inputValues={inputValues}
            />
          )}
        </>
      )}
    </>
  );
};

export default Main;
