import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';

export default function DynamicTabs({ tabNames, tabContentComponents,item }) {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box  className='w-full m-5  h-[40vh] min-h-[100px] p-2 border-2 border-gray'>
      <Tabs value={activeTab} onChange={handleChange} aria-label="dynamic tabs">
        {tabNames.map((name, index) => (
          <Tab className='bg-gray-300 border-2 border-gray-500 ml-1 rounded-lg' label={name} key={name} />
        ))}
      </Tabs>
      {tabContentComponents.map((ContentComponent, index) => (
        <div
          role="tabpanel"
          hidden={activeTab !== index}
          id={`tabpanel-${index}`}
          aria-labelledby={`tab-${index}`}
          key={index}
          className='bg-gray-100 rounded-xl  min-h-[100px] max-h-[500px] overflow-y-auto '
        >
          {activeTab === index && (
            <Box sx={{ p: 3 }} className=" " >
              {/* Pass the item prop to the ContentComponent */}
              <ContentComponent item={item} />
            </Box>
          )}
        </div>
      ))}
    </Box>
  );
}
