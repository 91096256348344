import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../../../slices/categorySlice';
import { useNavigate } from 'react-router-dom';

function Category({ handleClick, features }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleImageListItemClick = (id) => {
    console.log('click c', id);
    dispatch(selectCategory({ id, level: 1 }));
    handleClick(id, 0);
    navigate(`/moreDetails/${id}`);
  };

  console.log('features', features);

  return (
    <div className="p-20 flex justify-center items-center shadow-md border-b">
      <ul className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:w-3/4">
        {features && features.length > 0 && (
          <>
            {features
              .filter((item) => item.level === 0)
              .map((item, id) => (
                <li
                  key={id}
                  className="bg-white space-y-3 p-3 rounded-lg flex gap-4 items-center ml-10"
                  onClick={() => handleImageListItemClick(item.id)}
                >
                  <div
                    className="bg-green-400 p-2 flex justify-center items-center"
                    style={{ borderRadius: '50%' }}
                  >
                    <div className="bg-green-400 rounded-full p-2">
                      <img width="25" height="25" src={item.img_url} alt="Home Icon" />
                    </div>
                  </div>
                  <div>
                    <h4 className="text-xs text-gray-800 font-semibold md:text-lg">{item.name}</h4>
                    <p>{item.desc}</p>
                  </div>
                </li>
              ))}
          </>
        )}
      </ul>
    </div>
  );
}

export default Category;
