import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

const RadioButton = ({ options, value, onChange ,name}) => {
  return (
    <>  
        <Typography variant='h4'>{name}</Typography>
        <FormControl component="fieldset">
        <RadioGroup value={value} onChange={onChange}>
            {options.map((option, index) => (
            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
            ))}
        </RadioGroup>
        </FormControl>
    </>
  );
};

export default RadioButton;
