import React from 'react'
import Header from '../components/Header'
import Search from '../../home/components/LandingPage/Search/Search'
import  Category from '../../home/components/LandingPage/Category/Category'
import Footer from '../../home/components/LandingPage/Footer/Footer'

const steps = ['Main Category', 'Filters', 'Select on Map'];

export default function LandingPage({features,handleClick}) {
  return (
    <>
    
    {/* <Header/> */}
    <Search/>
    <Category features={features} handleClick={handleClick}/>
    <Footer/>
    </>

  )
}
