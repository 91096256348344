'use client';
import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'; // Import the useDispatch hook

const LocationSearch = ({ searchValue, handleInputChange, setFieldValue }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const autocompleteService = useRef(null);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      return new Promise((resolve, reject) => {
        if (window.google) {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD_6yH-KwGXniV7oQPVangfCjx-veNl3a0&libraries=places`;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };

    loadGoogleMapsScript()
      .then(() => {
        initializeAutocomplete();
      })
      .catch((error) => {
        console.error('Error loading Google Maps script:', error);
      });

    return () => {
      if (document.querySelector("script[src*='https://maps.googleapis.com/maps/api/js']")) {
        document.head.removeChild(
          document.querySelector("script[src*='https://maps.googleapis.com/maps/api/js']")
        );
      }
    };
  }, []);

  const initializeAutocomplete = () => {
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  };

  const handleOptionClick = (suggestion) => {
    handleInputChange({ target: { value: suggestion.description } });
    setSelectedItem(suggestion);
    setFieldValue('city', suggestion.description);
    setSuggestions([]);
  };

  useEffect(() => {
    if (searchValue && autocompleteService.current) {
      autocompleteService.current.getPlacePredictions(
        { input: searchValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSuggestions(predictions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  }, [searchValue]);

  return (
    <div className="relative">
      {suggestions.length > 0 && (
        <div className="absolute z-10 bg-white w-full shadow-md rounded-lg mt-1">
          <ul>
            {suggestions.map((suggestion) => (
              <li
                className={`p-2 cursor-pointer hover:bg-gray-200 ${
                  selectedItem && selectedItem.place_id === suggestion.place_id ? 'bg-gray-200' : ''
                }`}
                key={suggestion.place_id}
                onClick={() => handleOptionClick(suggestion)}
              >
                {suggestion.description}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LocationSearch;
