import React, { useState } from 'react';
import axios from 'axios';
import LocationSearch from './LocationSearch'; // Adjust the import path as needed

export default function Search({ parent, setLocations }) {
  const [searchValue, setSearchValue] = useState('');
  const [results, setResults] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();

    if (!parent) {
      console.error('Parent ID is not provided');
      return;
    }

    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_CLIENT_API_URL
        }/advertisement/quary/search/${parent}?query=${encodeURIComponent(searchValue)}`
      );
      const data = response.data;

      if (data.length === 0) {
        setErrorMessage('No results found for your search.');
        setResults([]);
        setLocations([]);
      } else {
        const locations = data.map((item) => {
          const [lat, lng] = item.coordinates.split(',').map(Number);
          return {
            id: item.header_id,
            lat: lat,
            lng: lng,
            name: item.title,
            phone: item.phone,
            address: item.address,
            price: item.price,
            image: item.image_details[0]?.image_url || 'default_image_url'
          };
        });
        setResults(data);
        setLocations(locations);
        setErrorMessage('');
      }
    } catch (error) {
      console.error('There was an error fetching the data!', error);
      setErrorMessage('There was an error fetching the data.');
    }
  };

  return (
    <div className="m-5">
      <form onSubmit={handleSearch}>
        <label
          htmlFor="default-search"
          className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
        >
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>

          <input
            type="search"
            id="default-search"
            className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-50 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Where are you going?"
            value={searchValue}
            onChange={handleInputChange}
          />
          <button
            type="submit"
            className="text-white absolute right-2.5 bottom-2.5 bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-green-500 dark:hover:bg-green-700 dark:focus:ring-green-800"
          >
            Search
          </button>

          <LocationSearch
            searchValue={searchValue}
            handleInputChange={handleInputChange}
            setFieldValue={() => {}}
          />
        </div>
      </form>

      {errorMessage && <div className="mt-5 text-red-500">{errorMessage}</div>}

      {results.length > 0 && (
        <div className="mt-5">
          {/* <h2 className="text-lg font-medium text-gray-900 dark:text-white">Search Results:</h2> */}
          <ul className="list-disc pl-5">
            {results.map((result, index) => (
              <li key={index} className="text-sm text-gray-900 dark:text-white">
                {result.title} - {result.summary}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
