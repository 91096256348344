import { useState } from 'react';

// This is your custom hook
export const useModal = (defaultModal = 'filter') => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(defaultModal);
  const [item, setItem] = useState(null);
  const toggleModal = (modal, id) => {
    setIsModalOpen(!isModalOpen);
    if (modal) setActiveModal(modal);
    setItem(id)
    console.log(modal);
  };

  return { isModalOpen, toggleModal, setIsModalOpen, activeModal,item};
};
