import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './features/authentication/components/AuthGuard';
import { authRoles } from './features/authentication/utils/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import chatBotPageRoutes from 'app/views/chatbot/ChatBotPageRoutes';
import customerPageRoutes from './views/Customers/CustomerRoutes';
import RealEstateRoutes from './features/real_estates_properties/routes/Real_estate_routes';
// session pages
const NotFound = Loadable(lazy(() => import('app/features/authentication/pages/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/features/authentication/pages//JwtLogin')));
const JwtRegister = Loadable(lazy(() => import('app/features/authentication/pages//JwtRegister')));
const ForgotPassword = Loadable(
  lazy(() => import('app/features/authentication/pages/ForgotPassword'))
);

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: <MatxLayout />,
    children: [...RealEstateRoutes]
  },
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      ...chatBotPageRoutes,
      ...customerPageRoutes,

      // dashboard route
      {
        path: '/',
        element: <Analytics />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },
  { path: '/session/signup', element: <JwtRegister /> },
  { path: '/session/forgot-password', element: <ForgotPassword /> },
  { path: '/dashboard', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
