import React from 'react';
import RadioButton from './RadioButton';
import TextInput from './TextInput';
import SliderInput from './SliderInput';
import DropdownInput from './DropDown';
import { Box } from '@mui/system';
import { useAppSelector } from 'app/redux/store';

const Filters = ({ filters, inputValues, handleInputChange }) => {
  const { parent } = useAppSelector((state) => state.category);

  const renderInput = (filter) => {
    const { code, name, type, data } = filter;
    switch (type) {
      case 'radio':
        return (
          <RadioButton
            name={name}
            options={data}
            value={inputValues[code] || ''}
            onChange={(newValue) => handleInputChange(code, newValue)}
          />
        );
      case 'text':
        return (
          <TextInput
            name={name}
            value={inputValues[code] || ''}
            onChange={(newValue) => handleInputChange(code, newValue)}
          />
        );
      case 'slider':
        return (
          <SliderInput
            name={name}
            value={inputValues[code] || 0}
            onChange={(newValue) => handleInputChange(code, newValue)}
          />
        );
      case 'dropdown':
        return (
          <DropdownInput
            name={name}
            options={data}
            value={inputValues[code] || ''}
            onChange={(newValue) => handleInputChange(code, newValue)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ margin: '20px' }}>
      {parent}
      <Box maxWidth={400}>
        {filters
          .filter((filter) => parent === filter.parent)
          .map((filter) => (
            <div key={filter.code} style={{ margin: '10px 0' }}>
              <label className="text-xl">{filter.name}</label>
              {renderInput(filter)}
            </div>
          ))}
      </Box>
    </div>
  );
};

export default Filters;

// Exported RenderInput for use in FilterSelector
export const RenderInput = ({ filter, handleInputChange, inputValues }) => {
  const { id, name, type, data } = filter;
  switch (type) {
    case 'radio':
      return (
        <RadioButton
          name={name}
          options={data}
          value={inputValues[id] || ''}
          onChange={(newValue) => handleInputChange(id, newValue)}
        />
      );
    case 'text':
      return (
        <TextInput
          name={name}
          value={inputValues[id] || ''}
          onChange={(newValue) => handleInputChange(id, newValue)}
        />
      );
    case 'slider':
      return (
        <SliderInput
          name={name}
          value={inputValues[id] || 0}
          onChange={(newValue) => handleInputChange(id, newValue)}
        />
      );
    case 'dropdown':
      return (
        <DropdownInput
          name={name}
          options={data}
          value={inputValues[id] || ''}
          onChange={(newValue) => handleInputChange(id, newValue)}
        />
      );
    default:
      return null;
  }
};
