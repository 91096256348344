import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
import Main from '../home/pages/Main';

const Item = Loadable(lazy(() => import('../home/pages/Item')));
const CustomerPortal = Loadable(lazy(() => import('../home/pages/CustomerPortal')));
const MoreDetails = Loadable(lazy(() => import('../home/components/MapComponent')));
const LandingSearchResult = Loadable(
  lazy(() => import('../home/components/LandingPage/LandingSearch/LandingSearchResult'))
);

const RealEstateRoutes = [
  { path: '', element: <Main /> },
  { path: '/search', element: <LandingSearchResult /> },
  { path: '/landing/item/:id', element: <Item /> },
  { path: 'customerportal', element: <CustomerPortal /> },
  { path: '/moreDetails/:name', element: <MoreDetails /> } // Updated to include dynamic :id parameter
];

export default RealEstateRoutes;
