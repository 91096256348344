import React, { useRef, useState, useEffect, useCallback } from 'react';
import CategoryMore from './CategoryMore';
import AlignItemsList from './AlignItemsList';
import Modal from './Modal';
import Search from '../components/MainSearch/index';
import { useModal } from '../hooks/useModal';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  Autocomplete,
  DrawingManager,
  GoogleMap,
  Polygon,
  useJsApiLoader,
  Marker,
  InfoBox,
  MarkerClusterer
} from '@react-google-maps/api';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const libraries = ['places', 'drawing'];

const MapComponent = ({ inputValues, handleInputChange, filters = [] }) => {
  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const activePolygonIndex = useRef();
  const autocompleteRef = useRef();
  const drawingManagerRef = useRef();
  const [userLocation, setUserLocation] = useState(null);
  const [position, setPosition] = useState(null);
  const [showMarker, setShowMarker] = useState(false);
  const [all, setAll] = useState(null);
  const [categories, setCategories] = useState([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyD_6yH-KwGXniV7oQPVangfCjx-veNl3a0',
    libraries
  });
  const [parentValue, updateParentValue] = useState(0);
  const params = useParams();
  const { isModalOpen, setIsModalOpen, toggleModal, activeModal, item } = useModal();
  const [polygons, setPolygons] = useState([]);
  const [insidePolygons, setInsidePolygons] = useState([]);
  const [activeScreen, setActiveScreen] = useState(0);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [parent, setParent] = useState(0);
  const [currentLevel, setCurrentLevel] = useState(0);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    updateParentValue(params.name);
  }, [params]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CLIENT_API_URL}/landing/quary/get_all`)
      .then((response) => {
        const { hierarchicaldata, filters } = response.data.payload;
        setAll({ categories: hierarchicaldata, filters });
        setCategories(hierarchicaldata);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (parentValue) {
      axios
        .get(
          `${process.env.REACT_APP_CLIENT_API_URL}/advertisement/quary/get_advertisements_by_category/${parentValue}`
        )
        .then((response) => {
          const { payload } = response.data;
          const formattedLocations = payload.map((item) => {
            const [lat, lng] = item.coordinates.split(',').map(Number);
            return {
              id: item.id,
              lat: lat,
              lng: lng,
              name: item.title,
              phone: item.phone,
              address: item.address,
              price: item.price,
              image: item.image_details[0]?.image_url || 'default_image_url'
            };
          });
          setLocations(formattedLocations);
        })
        .catch((error) => {
          console.error('Error fetching advertisements:', error);
        });
    }
  }, [parentValue]);

  const handleMouseEnter = (location) => {
    if (location.lat && location.lng) {
      setShowMarker(true);
      setPosition({ lat: location.lat, lng: location.lng });
    }
  };

  const handleMouseLeave = () => {
    setShowMarker(false);
  };

  const defaultCenter = {
    lat: 28.626137,
    lng: 79.821603
  };

  const containerStyle = {
    display: 'flex',
    height: '100%'
  };

  const autocompleteStyle = {
    boxSizing: 'border-box',
    border: '1px solid transparent',
    width: '200px',
    height: '38px',
    padding: '0 12px',
    borderRadius: '3px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
    fontSize: '14px',
    outline: 'none',
    textOverflow: 'ellipses',
    position: 'absolute',
    right: '8%',
    top: '9px',
    marginLeft: '-120px'
  };

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: '#ff0000',
    strokeColor: '#ff0000',
    strokeWeight: 2,
    draggable: true,
    editable: true
  };

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [window.google?.maps?.drawing?.OverlayType?.POLYGON]
    }
  };

  const checkLocationsInsidePolygons = (locations, polygons) => {
    const locationsInsidePolygons = locations.filter((location) => {
      const point = new window.google.maps.LatLng(location.lat, location.lng);
      return polygons.some((polygonCoords) => {
        const polygon = new window.google.maps.Polygon({
          paths: polygonCoords
        });
        return window.google.maps.geometry.poly.containsLocation(point, polygon);
      });
    });
    return locationsInsidePolygons;
  };

  const onLoadMap = (map) => {
    mapRef.current = map;
  };

  const onLoadPolygon = (polygon, index) => {
    polygonRefs.current[index] = polygon;
  };

  const onClickPolygon = (index) => {
    activePolygonIndex.current = index;
  };

  const onLoadAutocomplete = (autocomplete) => {
    autocompleteRef.current = autocomplete;
  };

  const onPlaceChanged = () => {
    const { geometry } = autocompleteRef.current.getPlace();
    const bounds = new window.google.maps.LatLngBounds();
    if (geometry.viewport) {
      bounds.union(geometry.viewport);
    } else {
      bounds.extend(geometry.location);
    }
    mapRef.current.fitBounds(bounds);
  };

  const onLoadDrawingManager = (drawingManager) => {
    drawingManagerRef.current = drawingManager;
  };

  const onOverlayComplete = ($overlayEvent) => {
    drawingManagerRef.current.setDrawingMode(null);
    if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = $overlayEvent.overlay
        .getPath()
        .getArray()
        .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setPolygons([...polygons, newPolygon]);

      const locationsInsidePolygon = locations.filter((location) => {
        const point = new window.google.maps.LatLng(location.lat, location.lng);
        const polygon = new window.google.maps.Polygon({
          paths: newPolygon
        });
        return window.google.maps.geometry.poly.containsLocation(point, polygon);
      });
      setInsidePolygons(locationsInsidePolygon);
      console.log('Locations inside the new polygon:', locationsInsidePolygon);
    }
  };

  const onDeleteDrawing = () => {
    const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current);
    setPolygons(filtered);
    setInsidePolygons([]);
  };

  const onEditPolygon = useCallback(
    (index) => {
      const polygonRef = polygonRefs.current[index];
      if (polygonRef) {
        const coordinates = polygonRef
          .getPath()
          .getArray()
          .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

        const allPolygons = [...polygons];
        allPolygons[index] = coordinates;
        setPolygons(allPolygons);
        const locationsInsideEditedPolygon = checkLocationsInsidePolygons(locations, allPolygons);
        setInsidePolygons(locationsInsideEditedPolygon);
        console.log('Locations inside the edited polygon:', locationsInsideEditedPolygon);
      }
    },
    [polygons, setPolygons]
  );

  useEffect(() => {
    if (isLoaded) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [isLoaded]);

  const handleCategorySelect = (id, level) => {
    const updatedSelectedCategories = [...selectedCategories.slice(0, level), id];
    setSelectedCategories(updatedSelectedCategories);
    setParent(id);
    if (level === 0) {
      setActiveScreen(1);
    }
    setCurrentLevel(level + 1);
  };

  return isLoaded ? (
    <div className="bg-gray-100 flex min-h-[90vh]  max-h-[90vh]">
      <div className="flex-1 flxex ">
        <Search parent={parentValue} setLocations={setLocations} />

        {filters && (
          <Modal
            isModalOpen={isModalOpen}
            item={item}
            setIsModalOpen={setIsModalOpen}
            toggleModal={toggleModal}
            activeModal={activeModal}
            filters={filters}
            handleInputChange={handleInputChange}
            inputValues={inputValues}
          />
        )}
        <CategoryMore
          parentValue={parentValue}
          features={categories}
          handleClick={handleCategorySelect}
        />

        {insidePolygons.length === 0 ? (
          <></>
        ) : (
          <AlignItemsList
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            locations={insidePolygons}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            toggleModal={toggleModal}
            activeModal={activeModal}
          />
        )}
        {polygons.length === 0 && (
          <AlignItemsList
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            locations={locations}
            toggleModal={toggleModal}
          />
        )}
      </div>
      <div className="map-container border-8 m-5 border-gray-300 rounded-3xl bg-green-100 flex-1">
        {drawingManagerRef.current && (
          <IconButton
            className=" bg-green-500"
            onClick={onDeleteDrawing}
            title="Delete shape"
            style={{
              position: 'absolute',
              top: '100px',
              right: '30px',
              zIndex: 99999
            }}
          >
            <DeleteIcon />
          </IconButton>
        )}
        <GoogleMap
          zoom={7}
          center={userLocation || { lat: 0, lng: 0 }}
          onLoad={onLoadMap}
          mapContainerStyle={containerStyle}
        >
          {userLocation && <Marker position={userLocation} map={mapRef.current} />}
          {showMarker && position && (
            <InfoBox position={position}>
              <div className=" bg-gray-500 justify-center text-center text-white p-5">
                <h1 className="text-3xl ">{position.name}</h1>
                <p className="text-base ">{`Price: ${position.price}`}</p>
                <img src={position.image} className=" max-w-[50px] max-h-[50px]" />
              </div>
            </InfoBox>
          )}
          <MarkerClusterer>
            {(clusterer) =>
              locations.map((loc) => (
                <Marker
                  key={loc.id}
                  position={{ lat: loc.lat, lng: loc.lng }}
                  clusterer={clusterer}
                  label={`${loc.name} price ${loc.price}`}
                />
              ))
            }
          </MarkerClusterer>
          <DrawingManager
            onLoad={onLoadDrawingManager}
            onOverlayComplete={onOverlayComplete}
            options={drawingManagerOptions}
          />
          {polygons.map((iterator, index) => (
            <Polygon
              key={index}
              onLoad={(event) => onLoadPolygon(event, index)}
              onMouseDown={() => onClickPolygon(index)}
              onMouseUp={() => onEditPolygon(index)}
              onDragEnd={() => onEditPolygon(index)}
              options={polygonOptions}
              paths={iterator}
              draggable
              editable
            />
          ))}
          <Autocomplete onLoad={onLoadAutocomplete} onPlaceChanged={onPlaceChanged}>
            <input type="text" placeholder="Search Location" style={autocompleteStyle} />
          </Autocomplete>
        </GoogleMap>
      </div>
    </div>
  ) : null;
};

export default MapComponent;
