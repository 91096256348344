import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { LocalOffer } from '@mui/icons-material';
import { togglePin, toggleFavorite } from '../slices/categorySlice';
import { useDispatch, useSelector } from 'react-redux'; // Import useSelector hook
import { selectCategory } from '../slices/categorySlice'; // Import selectCategory selector
import { Rating } from '@mui/material';

const MyCard = ({
  image,
  buttons,
  toggleModal,
  id,
  handleMouseEnter,
  handleMouseLeave,
  location
}) => {
  const dispatch = useDispatch(); // Initialize useDispatch hook

  // Retrieve pinned and liked locations from Redux store
  const pinnedLocations = useSelector((state) => state.category.pinnedAds);
  const likedLocations = useSelector((state) => state.category.likedAds);

  const [rating, setrating] = useState(false);
  const handlePinClick = (id) => {
    dispatch(togglePin(id)); // Dispatch togglePin action with location id
  };

  const handleFavoriteClick = (id) => {
    dispatch(toggleFavorite(id)); // Dispatch toggleFavorite action with location id
  };

  // Check if the current location is pinned or liked
  const isPinned = pinnedLocations.includes(location.id);
  const isLiked = likedLocations.includes(location.id);

  return (
    <div
      className="rounded bg-white-500 shadow-lg"
      style={{ margin: 0 }}
      onMouseEnter={() => handleMouseEnter(location)}
      onMouseLeave={handleMouseLeave}
    >
      <img
        className="w-full"
        src={location.image}
        alt="Mountain"
        style={{ maxHeight: '250px', objectFit: 'cover', marginTop: 0 }}
      />
      <div className="p-4" style={{ position: 'relative' }}>
        <Link to={`/landing/item/${location.id}`}>
          <div className="font-bold text-2xl mb-2">House</div>
          <p className="text-gray-700 text-base mb-2">
            Apartment / 2 rum / 44m² Furnished
            <br /> price {location.price}
          </p>
          <p className="text-gray-700 text-base mb-2">
            <b> video link </b> <a>www.asdasdasd.com</a>
          </p>
        </Link>

        {/* Favorite Icon */}
        <span>
          <Tooltip title="Favorite" placement="top">
            <IconButton
              style={{
                position: 'absolute',
                top: '5px',
                right: '5px',
                color: isLiked ? '#e1306c' : 'inherit'
              }}
              onClick={() => {
                handleFavoriteClick(location.id);
                setrating(true);
              }}
            >
              <FavoriteIcon />
            </IconButton>
          </Tooltip>
        </span>

        {/* <span>
          <Tooltip title="Pin" placement="top">
            <IconButton
              style={{
                position: 'absolute',
                top: '5px',
                right: '35px',
                color: isPinned ? 'green' : 'inherit'
              }}
              onClick={() => handlePinClick(location.id)}
            >
              <LocalOffer />
            </IconButton>
          </Tooltip>
        </span> */}

        {/* Pin Icon */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className="flex flex-wrap">
            {buttons &&
              buttons.map((buttonLabel, index) => (
                <button
                  onClick={() => toggleModal('tools', location.id)}
                  key={index}
                  className="bg-green-200 rounded-1 px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-green-400"
                >
                  {buttonLabel}
                </button>
              ))}
          </div>
          {isLiked && <Rating name="size-large" defaultValue={2} size="large" />}
        </div>
      </div>
    </div>
  );
};

export default MyCard;
