import React from 'react';
import { styled, Slider, Typography } from '@mui/material';

const CustomSlider = styled(Slider)({
  
  // Styles for the thumb (cursor) of the slider
  '& .MuiSlider-thumb': {
    color: '#3CC68A',
  },
  '& .MuiSlider-rail':{
    color:'lightgreen'
  },
  '& .MuiSlider-track':{
    color: '#3CC68A'
  }
});

const SliderInputMui = ({ value, onChange, name }) => {
  return (
    <div>
      <CustomSlider
        value={value}
        onChange={(_, newValue) => onChange(newValue)}
        aria-labelledby="slider-label"
      />
    </div>
  );
};

export default SliderInputMui;
