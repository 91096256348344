import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Landing from '../../../../home/asset/Landing.png';

function Search() {
  const [searchValue, setSearchValue] = useState('');
  
  // React Router's navigate function
  const navigate = useNavigate();

  const handleSearch = () => {
    if (searchValue.trim()) {
      navigate('/search', { state: { query: searchValue } });
    }
  };


  return (
    <div class="bg-ecf1f5 shadow-md">
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="flex flex-col justify-center md:pr-8 xl:pr-0 lg:max-w-lg">
            <div className="max-w-xl mb-6">
              <h2
                className="'max-w-lg mb-6 font-sans text-3xl font-bold tracking-tight text-gray-900 text-left sm:text-4xl sm:leading-none'
"
              >
                Exploring Boundless <br className="hidden md:block" />
                Horizons Where{' '}
                <span className="inline-block text-deep-purple-accent-400">
                  Aspirations Come True
                </span>
              </h2>
            </div>

            <div className="flex space-x-4">
              <input
                type="text"
                placeholder="What are you looking for?"
                className="border border-black-300 rounded p-2 w-3/4"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)} // Update state on input change
              />
              <button className="bg-green-400 text-white rounded p-2 w-1/4 " onClick={handleSearch} >Search</button>
            </div>
          </div>
          <div className="flex items-center justify-center -mx-4 lg:pl-8">
            <div className="flex flex-col items-end px-3">
              <img
                // className="object-cover mb-6  h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56"
                src={Landing}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Search;
