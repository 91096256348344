import React from 'react';
import { TextField ,Typography} from '@mui/material';
import styled from 'styled-components';

const CustomerTextField= styled(TextField)({
    color: 'darkgreen',
    borderRadius: '8px',
    border:'1px solid #3CC68A'
})
const TextInput = ({ value, onChange,name }) => {
  return <>
    {/* <Typography variant='h4'>{name}</Typography> */}
    <CustomerTextField label="Text" variant="outlined" value={value} onChange={(e) => onChange(e.target.value)} />;
  </>
};

export default TextInput;
