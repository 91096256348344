import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Filters, { RenderInput } from './Filters';
import { useAppSelector, useAppDispatcher } from 'app/redux/store';
import { CloseOutlined } from '@mui/icons-material';
import { setInputFilterValues, addFilters } from '../slices/categorySlice';

const FilterSelector = () => {
  const dispatch = useAppDispatcher();
  const { parent, inputFilterValues, filters } = useAppSelector((state) => state.category);

  console.log('parent', parent);
  console.log('inputFilterValues', inputFilterValues);
  console.log('filters', filters);

  const [options, setOptions] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_CLIENT_API_URL}/landing/quary/get_all_filter_data`)
      .then((response) => {
        const filteredOptions = response.data.filter(
          (option) => option.category === 'DI_N' || option.category === 'F_N'
        );
        setOptions(filteredOptions || []); // Ensure filteredOptions is an array
        console.log('filteredOptions', filteredOptions);
      })
      .catch((error) => {
        console.error('Error fetching filter options:', error);
      });
  }, []);

  const handleInputChange = (code, value) => {
    dispatch(setInputFilterValues({ ...inputFilterValues, [code]: value }));
  };

  const handleAddFilter = (filter) => {
    const selectedOption = options.find((option) => option.name === filter);

    if (selectedOption) {
      dispatch(addFilters([...filters, selectedOption]));
    }
  };

  const handleRemoveFilter = (filter) => {
    const updatedFilters = filters.filter((f) => f !== filter);
    dispatch(addFilters(updatedFilters));
  };

  const filteredOptions = options.filter((option) => option.ref_id === parent);

  return (
    <Box>
      <Autocomplete
        id="filter-autocomplete"
        options={filteredOptions.map((option) => option.name)}
        disablePortal
        renderInput={(params) => <TextField {...params} label="Filter" variant="outlined" />}
        onChange={(event, value) => handleAddFilter(value)}
      />

      <Box mt={2} maxWidth={400}>
        {filters.map((filter) => (
          <div key={filter.code} style={{ margin: '10px 0', alignItems: 'center' }}>
            <label className="text-xl">{filter.name}</label>

            <RenderInput
              filter={filter}
              handleInputChange={handleInputChange}
              inputValues={inputFilterValues}
            />

            <Button onClick={() => handleRemoveFilter(filter)}>
              <CloseOutlined />
            </Button>
          </div>
        ))}
      </Box>

      <Box mt={2}>
        <Button variant="contained" color="primary" onClick={() => dispatch(addFilters([]))}>
          Clear All Filters
        </Button>
      </Box>
    </Box>
  );
};

export default FilterSelector;
