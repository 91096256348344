import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DynamicTabs from './DynamicTabs';
import { useModal } from '../hooks/useModal';
import { Button, Typography } from '@mui/material';
import Filters from './Filters';
import FilterSelector from './FilterSelector';
import { SendAndArchiveOutlined } from '@mui/icons-material';
const style = {
  position: 'absolute',
  top: '40%',
  left: '0%',
  transform: 'translate(0%, -50%)',
  width: '40%', // Removed the duplicate width property
  height: '60%',
  boxShadow: 24,
  p: 4
};
const TabOneContent = ({ item }) => <div>{`Content for Analytics and item is ${item}`}</div>;
const TabTwoContent = ({ item }) => <div>{`Content for Insurance and item is ${item}`}</div>;
const TabThreeContent = ({ item }) => <div>{`Content for Leasing and item is ${item}`}</div>;

const CustomModal = ({
  isModalOpen,
  toggleModal,
  setIsModalOpen,
  activeModal,
  item,
  inputValues,
  handleInputChange,
  filters
}) => {
  return (
    <>
      {/* Buttons container */}
      <div className="flex m-5">
        {/* Filter button */}
        <button
          onClick={() => toggleModal('filter', 0)}
          className="text-xl m-5 text-gray-700 bg-transparent hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-transparent border-gray-400 border-2 dark:hover:bg-green-400 dark:hover:text-white"
          type="button"
        >
          Filter
        </button>
        {/* Tools button */}
        <button
          onClick={() => toggleModal('tools', 0)}
          className="text-xl text-gray-700 m-5 bg-transparent hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-transparent border-gray-400 border-2 dark:hover:bg-green-400 dark:hover:text-white ml-2" // Added ml-2 for margin-left
          type="button"
        >
          Tools
        </button>
        <button
          onClick={() => toggleModal('analytics', 0)}
          className="text-xl text-gray-700 m-5 bg-transparent hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg px-5 py-2.5 text-center dark:bg-transparent border-gray-400 border-2 dark:hover:bg-green-400 dark:hover:text-white ml-2" // Added ml-2 for margin-left
          type="button"
        >
          Analytics
        </button>
      </div>

      {/* Main modal */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="bg-gray-200 rounded-xl m-5 overflow-y-auto" sx={style}>
          {activeModal === 'filter' && (
            <>
              <FilterSelector
                filters={filters}
                handleInputChange={handleInputChange}
                inputValues={inputValues}
              />
            </>
          )}

          {activeModal === 'analytics' && (
            // <DynamicTabs item={item}
            // //   tabNames={['Analtics', 'Insurance', 'Leasing']}
            // //   tabContentComponents={[TabOneContent, TabTwoContent, TabThreeContent]}
            // tabNames={['Analtics']}
            //   tabContentComponents={[TabOneContent]}
            // />
            <img
              src="/assets/analytics.jpeg"
              alt="Description of the image"
              className="h-[470px] r-5"
            />
          )}

          {activeModal === 'tools' && (
            <Box className="align-down flex items-center p-11 flex-col">
              {/* <Typography variant='h5' className='mb-8'> Tools</Typography> */}
              <Button
                variant="contained"
                className="bg-green-500 hover:bg-green-700 mb-8"
                endIcon={<SendAndArchiveOutlined />}
              >
                Loan Calculator
              </Button>
              <Button
                variant="contained"
                className="bg-blue-500"
                endIcon={<SendAndArchiveOutlined />}
              >
                Insurance Calculator
              </Button>
            </Box>
          )}

          <Button
            className="absolute top-5 right-5"
            onClick={toggleModal}
            variant="outlined"
            color="primary"
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default CustomModal;
